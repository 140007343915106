@import "src/themes/daikiri/styles/index.scss";
.qr-preview {
  background-color: getvar($colors, "neutral", "50");
  width: space(22);

  @include md {
    position: fixed;
    overscroll-behavior: none;
  }

  &__canvas {
    background-color: white;
    padding: space(0) space(2);
    border-radius: 42px;
    height: 98%;
    width: 65%;
    margin-left: 18px;

    &__wrapper {
      width: 100%;
      height: 100%;

      canvas {
        width: 100%;
        height: 100%;
      }

      &--frame-black {
        canvas {
          border: 12px solid #d1d131;
          box-sizing: content-box;
          padding: 6px;
        }
      }

      &--none {
        canvas,
        img {
          left: 50%;
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          height: auto;
          width: 96%;
        }
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    //overwrite buttom styles in frame type button
    .button {
      background-color: transparent !important;
      border: none !important;
      cursor: auto !important;
      padding: 0;
    }

    .frame {
      canvas {
        display: block;
        margin: 0px !important;
      }
      &.black,
      &.mobile,
      &.white {
        canvas {
          height: 62px;
          width: 61.3px;
        }
      }

      &.button {
        canvas {
          height: 61px;
          width: 61px;
        }
      }
      &.ticket {
        canvas {
          height: 62px;
          width: 62px;
        }
      }
      &.museum {
        canvas {
          height: 60px;
          width: 58px;
        }
      }
      &.cafe {
        canvas {
          height: 43px;
          width: 44px;
        }
      }
      &.shopping {
        canvas {
          height: 56px;
          width: 57px;
        }
      }
      &.board {
        canvas {
          height: 56px;
          width: 55px;
        }
      }
      &.envelope {
        canvas {
          height: 54px;
          width: 54px;
        }
      }
      &.receipt {
        canvas {
          height: 61px;
          width: 59px;
        }
      }
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    margin-bottom: space();
    text-align: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: space(1);

    p {
      margin: 0 0 0.5rem;
    }

    &__buttons-toggle {
      background: getvar($colors, "neutral", "100");
      display: flex;
      padding: 7px 8px;
      border-radius: space(6.25);
    }
  }

  &__layout {
    &__choose {
      background-color: getvar($colors, "primary", "50");
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: space(7.25) space(5);

      & p {
        color: getvar($colors, "neutral", "900");
        max-width: fit-content;
        @extend .u-text-center;
      }
    }
  }

  &__layout__image {
    // height: 525px;
    height: 572px;
    width: 287px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .qr-preview-img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__ai {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
