@import "src/themes/daikiri/styles/index.scss";
.Dashboard {
  &__header {
    display: flex;
    flex-direction: column;
    background-color: getvar($colors, "neutral", "50");
    padding: space(1.5) space() space(0.5) space(1);
    width: 100%;
    box-sizing: border-box;

    @include md {
      padding: 2.5rem 1.5rem 1rem 1.5rem;
    }

    &--mobile {
      display: block;
      .button--generate {
        font-size: space(1) !important;
        padding-left: space(1.563);
        padding-right: space(1.563);
        @include md {
          font-size: space(1.25);
        }
      }

      @include md {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include md {
        display: block;
      }
      .button--icon {
        font-size: 20px !important;
      }
    }

    &--compact {
      @include sm {
        padding: space(2.5) space(3) space(0.5) space(3);
      }
    }
  }
}
